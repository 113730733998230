import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "drawing-canvas",
    style: _normalizeStyle({
      cursor: `url('${_ctx.brushSize.cursor}') ${Math.ceil(
        _ctx.brushSize.size / 2
      )} ${Math.ceil(_ctx.brushSize.size / 2)}, pointer`,
    })
  }, [
    _createElementVNode("div", {
      ref: "canvasContainer",
      class: "canvas-container",
      onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPosition($event))),
      onMouseup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendCanvasData())),
      onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPosition($event))),
      onMousemove: _cache[3] || (_cache[3] = ($event: any) => (_ctx.draw($event))),
      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.clickOnCanvas($event)), ["stop"]))
    }, [
      _createElementVNode("canvas", {
        width: "900",
        height: "675",
        ref: "canvas",
        class: _normalizeClass({ disabled: _ctx.disabled })
      }, null, 2)
    ], 544)
  ], 4))
}
export default [
  '#000000',
  '#FFFFFF',

  '#4C4C4C',
  '#C1C1C1',

  '#730D0C',
  '#EE1A1B',

  '#C13A13',
  '#FE7222',

  '#E7A22B',
  '#FFE43C',

  '#045518',
  '#14CB30',

  '#04569B',
  '#11B2FB',

  '#0E0962',
  '#2320CE',

  '#550266',
  '#A208B6',

  '#A65673',
  '#D27DA9',

  '#633012',
  '#9F5332'
]


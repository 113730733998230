import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "player-icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.players, (player) => {
      return (_openBlock(), _createElementBlock("div", {
        key: player.id,
        class: "player_icon"
      }, [
        _createVNode(_component_avatar, {
          size: 70,
          username: player.name ? player.name : ''
        }, null, 8, ["username"]),
        _createElementVNode("p", null, _toDisplayString(player.name), 1)
      ]))
    }), 128))
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "brush-selector" }
const _hoisted_2 = { class: "sizes" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "tools" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BrushSizes, (size) => {
        return (_openBlock(), _createElementBlock("div", {
          key: size.size,
          class: _normalizeClass(["size", { selected: _ctx.brushSize.size === size.size }]),
          style: _normalizeStyle({
          width: size.size + 'px',
          height: size.size + 'px',
        }),
          onClick: ($event: any) => (_ctx.$emit('brush-size-selection', size))
        }, null, 14, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brushTools, (tool) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tool.type,
          class: _normalizeClass(["tool", { selected: _ctx.brushTool.type === tool.type }]),
          onClick: ($event: any) => (_ctx.$emit('brush-tool-selection', tool))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["fas", tool.icon])
          }, null, 2)
        ], 10, _hoisted_5))
      }), 128))
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  key: 0,
  class: "dropdown_items"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "dropdownLabel",
      class: "dropdown_label",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleIsDropdownOpen()))
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem), 1),
      _createElementVNode("i", {
        class: _normalizeClass(["fas fa-chevron-down", {
          'rotate-up': _ctx.isDropdownOpen,
        }])
      }, null, 2)
    ], 512),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item,
              class: "dropdown_items_item",
              onClick: ($event: any) => (_ctx.selectItem(item))
            }, [
              _createElementVNode("p", null, _toDisplayString(item), 1)
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
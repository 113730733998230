import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "color-selector" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ColorSelections, (color) => {
      return (_openBlock(), _createElementBlock("div", {
        key: color,
        class: _normalizeClass(["color", { selected: _ctx.selectedColor === color }]),
        style: _normalizeStyle({ backgroundColor: color }),
        onClick: ($event: any) => (_ctx.$emit('color-selection', color))
      }, null, 14, _hoisted_2))
    }), 128))
  ]))
}